import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import WebserviceIcon from '@material-ui/icons/PlayArrow'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import LaunchIcon from '@material-ui/icons/Launch'
import PlayIcon from '@material-ui/icons/PlayArrow';

import Dialog from '@material-ui/core/Dialog';

import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

const useStyles = makeStyles((theme) => ({

    container: {
        flex: 1,
        display: 'flex',
        flexFlow: "column",
        width: "60%",
        margin: "auto",
        padding: "20px",
        borderRadius: "4px",
        backgroundColor: "#D9D9D9"
    },

    paragraph: {
        textAlign: "justify",
        marginBottom: "40px"
    },

    instrImg: {
        borderRadius: "15px",
        margin: "10px 0px",
        border: "2px solid #AAA"


    }

}));

export default function Instructions() {

    const classes = useStyles()
    const plyrRef = useRef()

    const [videoOpen, setVideoOpen] = React.useState(false);

    function handleVideoOpen() {
        setVideoOpen(true);
        console.log(plyrRef)
    }

    function handleVideoClose() {
        setVideoOpen(false);
    }

    return (

        <>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                onClose={handleVideoClose}
                open={videoOpen}
            >
                <Plyr
                    ref={plyrRef}
                    autoplay='true'
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: "instructions.mp4"
                            }
                        ]
                    }}
                />
            </Dialog>

            

            <Box className={classes.container}>
                <Typography variant="h4" gutterBottom>
                    <b>Hot to use the CADA web service?</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    The CADA web service lets you search and select phenotypes based on the human phenotype ontology (HPO). After every update (selection or removal of HPO Terms), the resulting list of associated genes and their CADA scores will be recalculated and updated. Watch our short instruction video to get a quick overview or continue with the rest of the manual to learn more about how to manage HPO terms and how to handle results.<br/>
                    <Button
                        onClick={handleVideoOpen}
                        variant="contained"
                        color="secondary"
                        startIcon={<PlayIcon style={{ marginTop: "2px" }} />}
                        style={{ marginTop: "20px" }}
                    >
                        Watch our short instructions video!
                    </Button>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    <b>1 Select Phenotypes</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    One way to search and select phenotypic features is to query CADA's internal HPO database. This search works with keywords or with HPO term IDs. An other way to select multiple HPO terms at once, is to parse any kind of textual input and let CADA find the occuring HPO term IDs automatically.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    <b>Search HPO terms</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    The basic HPO term search starts by clicking the search field. This gives you a cursor in the search field and you can start typing keywords or HPO term ids.<br/>
                    <img className={classes.instrImg} src={"instr_1.png"} /><br/>
                    The search results will be instantaneously updated after every search query change (even on single keystrokes). This makes it possible to instantenously adjust your search query until you found the HPO term you were looking for. It is also possible to search through the result list by using the page navigation.  <br/>
                    <img className={classes.instrImg} src={"instr_2.png"} /><br/>
                    You can select an HPO term in the search results by clicking on it. After selecting, the HPO term will be listed with the other selected HPO terms below the search field.<br/>
                    <img className={classes.instrImg} src={"instr_3.png"} /><br/>
                    This search and select process should be repeated until you found all the HPO terms you were looking for. In case you want to remove a selected HPO term you can do this by clicking its 'x'-button on the right. If you want to clear all selected terms, you can do this by clicking on the 'x'-button in the top right.<br/>
                </Typography>

                <Typography variant="h6" gutterBottom>
                    <b>Parse HPO terms from text</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    To start using this feature, click on the 'add list' symbol on right of the search field.<br/>
                    <img className={classes.instrImg} src={"instr_4.png"} /><br/>
                    This will open the above view which also includes a basic how-to-use instruction. The left textfield can be used to copy paste any text into it. By clicking the arrow button, CADA parses the text and lists all found HPO terms in the list on the right. Here you can mark (or unmark) parsed HPO terms. By clicking the 'apply'-button, all the marked terms will be added to your selection on the main page.<br/>
                    <img className={classes.instrImg} src={"instr_5.png"} /><br/>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    <b>2 Associated genes</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    The associated gene view shows you the ordered list of scored genes that results from the your current HPO term selection. This view is live updated and everythime you add or remove an HPO term, also the list of associated genes will be updated. By using the page navigation, you can search through the gene list.<br/>
                    <img className={classes.instrImg} src={"instr_6.png"} /><br/>
                    In some cases, you do not want to see the score ordered gene list but instead, you are interested in just a few or even a single gene. In this case you can filter the list by entering comma-separated gene names in the filter field above the gene list. After that, you will only see the filtered genes in the list.<br/>
                    <img className={classes.instrImg} src={"instr_7.png"} /><br/>
                </Typography>

                <Typography variant="h6" gutterBottom>
                    <b>Gene score detail view</b>
                </Typography>

                <Typography className={classes.paragraph} variant="body1">
                    By clicking on a gene in the associated gene list, you will see the detailed score view for that gene.<br/>
                    <img className={classes.instrImg} src={"instr_8.png"} /><br/>
                    This view shows you the conribution for every single HPO term to the overall CADA score. Further, you will find links to the OMIM links and phenotypes.
                </Typography>

            </Box>
        </>

    )

}

