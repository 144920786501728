import React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import WebserviceIcon from '@material-ui/icons/PlayArrow'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import LaunchIcon from '@material-ui/icons/Launch'
import PdfIcon from '@material-ui/icons/PictureAsPdf'

const useStyles = makeStyles((theme) => ({

  container: {
    flex: 1,
    display: 'flex',
    flexFlow: "column",
    width: "60%",
    margin: "auto",
    padding: "20px",
    borderRadius: "4px",
    backgroundColor: "#D9D9D9"
  },

  paragraph: {
    textAlign: "justify",
    marginBottom: "40px"
  }

}));

export default function Home() {

  const classes = useStyles()

  return (
    
    <Box className={classes.container}>
      <Typography variant="h5" gutterBottom>
        <b>Introduction</b>
      </Typography>
      <Typography className={classes.paragraph} variant="body1">
        CADA is a phenotype-based gene prioritization tool that harnesses the most recent information from ClinVar. By this means human phenotype ontology (HPO) terms from case annotations as well as disorder annotations (CADA) are used for achieving superior results particularly for all typical clinical presentations of patients with rare disorders. 
CADA has been evaluated on a data set of 4714 molecularly confirmed cases and is used in the prospective study <a href="http://tnamse.de">TRANSLATE NAMSE</a>.
Please see our paper for more details on the methods.        
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>Publications</b>
      </Typography>
      <Typography className={classes.paragraph} variant="body1" style={{fontWeight: 'bold', display: 'flex', flexFlow: 'row'}}>
        <Link target="_blank" href="https://academic.oup.com/nargab/article/3/3/lqab078/6363753">CADA: phenotype-driven gene prioritization based on a case-enriched knowledge graph</Link>
        &nbsp;
        <Link target="_blank" href="https://academic.oup.com/nargab/article-pdf/3/3/lqab078/40302779/lqab078.pdf">
          <PdfIcon/>
        </Link>        
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>Webservice</b>
      </Typography>
      <Typography className={classes.paragraph} variant="body1">
        Start using the CADA webservice:
        <Link href="/webservice" style={{marginLeft: "10px"}}>
         <Button
            variant="contained"
            color="secondary"
            startIcon={<WebserviceIcon style={{marginBottom: "2px"}}/>}
          >          
            Webservice
          </Button>
        </Link>
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>Resources</b>
      </Typography>
      <Typography className={classes.paragraph} variant="body1">
        <Link target="_blank" href="https://www.ncbi.nlm.nih.gov/clinvar/"><Button style={{marginBottom: "8px"}} variant="contained" color="primary" endIcon={<LaunchIcon style={{marginBottom: "2px"}}/>}>ClinVar</Button></Link><br/>
        <Link target="_blank" href="https://hpo.jax.org/app/"><Button style={{marginBottom: "8px"}} variant="contained" color="primary" endIcon={<LaunchIcon style={{marginBottom: "2px"}}/>}>HPO</Button></Link><br/>
        <Link target="_blank" href="http://tnamse.de"><Button style={{marginBottom: "8px"}} variant="contained" color="primary" endIcon={<LaunchIcon style={{marginBottom: "2px"}}/>}>TRANSLATE NAMSE</Button></Link><br/>
      </Typography>
    </Box>

  )

}

