
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ViewColumn } from '@material-ui/icons'

LoadingOverlay.propTypes = {
    // TODO
}

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        transition: 'opacity 0.3s linear'
    },

    fadeIn: {
        zIndex: 100,
        opacity: 1.0
    },

    fadeOut: {
        opacity: 0.0
    },

    hide: {
        opacity: 0.0,
        zIndex: -100
    }
}))

export default function LoadingOverlay(props) {

    const classes = useStyles();

    const [boxClasses, setBoxClasses] = React.useState([
        classes.root,
        classes.hide
    ])

    useEffect( () => {
        if(props.open) {
            setBoxClasses([
                classes.root,
                classes.fadeIn
            ])
        } else {
            setBoxClasses([
                classes.root,
                classes.fadeOut
            ])
            setTimeout( () => 
                setBoxClasses([
                    classes.root,
                    classes.hide
                ]), 500
            )
        }
    },
    [props.open] );

    return (
        <Box align='center' className={clsx(boxClasses)} >
            <CircularProgress/>
        </Box>
    )
}

