
import React from 'react'
import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'


import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import LoadIcon from '@material-ui/icons/PlaylistAddTwoTone'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ApplyIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Clear'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'

import ProcessIcon from '@material-ui/icons/KeyboardArrowRight'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

import Checkbox from '@material-ui/core/Checkbox'

import XRegExp from 'xregexp'

const useStyles = makeStyles((theme) => ({

    dialog: {
        minHeight: '60%'
    },

    dialogContent: {
        display: 'flex',
        flexFlow: 'column',
        marginTop: '0px',
        marginBottom: '0px'
    },

    inputFieldRoot: {

        '& .MuiInputBase-root': {
            flex: 1,
            alignItems: 'flex-start',
        }
    },

    extractedTerms: {
        display: 'flex',
        flex: '1',
        paddingTop: 0,
        paddingBottom: 0,
        // border: '1px solid red', 
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
    },

    dialogActions: {
        padding: '8px 24px 16px 8px'
    },

    tableContainer: {
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',

    },

    table: {
        paddingTop: 0,
        paddingBottom: 0,
        // backgroundColor: '#D9D9D9',
    },

    cell: {
        padding: 0,
        borderBottom: '1px dashed rgba(0, 0, 0, 0.085)',
    },

    checkboxCell: {
        whiteSpace: 'nowrap',
        width: '1%'
    },

    idCell: {
        whiteSpace: 'nowrap',
        width: '1%'
    },

    nameCell: {
        paddingLeft: '4px'
    },

    th: {
        padding: 0,
        paddingLeft: '16px',
        paddingTop: '12px',
        paddingBottom: '6px',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.085)'
    },

}))

export default function HpoListImport(props) {

    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const [terms, setTerms] = React.useState([])
    // const [inputString, setInputString] = React.useState('kejhrkhergHP:000037234tq34klnöerlhp:123456789knenr4t34mt34klthp:0000125lkenrg9o3i4ht4khp:0000346ergklermrgerghp:0000923ölrgergwef wef w[HP:0006877]wwef we[HP:0030504]f wef wefef ')
    const [inputString, setInputString] = React.useState('')

    function openDialog() {
        setOpen(true)
    }


    function findTerm(termId) {
        for(let term of terms) {
            if(term.id === termId) {
                return term
            }
        }
        return null
    }


    function parseText() {

        let extractedIds = []
        XRegExp.forEach( inputString, /HP:\d{7}/i, (match,i) => extractedIds.push(match[0]) )
        extractedIds = extractedIds.map(termId => termId.toUpperCase())

        let newTerms = []

        extractedIds.map(extractedId => {

            let term = findTerm(extractedId)

            if(term === null) {

                term = {
                    id: extractedId
                }

                if(extractedId in props.hpoData) {

                    term.isValid = true
                    term.name = props.hpoData[extractedId].name

                    if(props.hpoData[extractedId].isAvailableInCada) {
                        term.checked = true
                        term.isAvailableInCada = true
                    } else {
                        term.checked = false
                        term.isAvailableInCada = false
                    }

                } else {
                    term.isValid = false
                    term.name = null
                    term.checked = false
                    term.isAvailableInCada = false
                    term.cadaSynonyms = []
                }
            }

            newTerms.push(term)
        })

        setTerms(newTerms)
    }


    function handleTermClick(index,event) {
        if(index >= 0 && index < terms.length) {
            let newTerms = terms.slice()
            let term = terms[index]
            if(term.isValid && term.isAvailableInCada) {
                term.checked = !term.checked
            }
            setTerms(newTerms)
        }
    }

    /*
        TODO:

            1. in der API: werte normalisieren
            2. in der API: rückgabe mit zwischenwerten für die detail view

            3. Die detail view im user interface
            
            4. Eine filterfunktion in der gene table
    */

   function closeDialog() {
        setTerms([])
        setInputString("")
        setOpen(false)
    }   



    function applySelection() {

        let returnIds = []
        
        terms.map( term => {
            if(term.checked) {
                returnIds.push(term.id)
            }
        })

        props.onApplySelection(returnIds)

        closeDialog()
    }



    function renderTerm(term,index) {
        return (
            <TableRow
                key={term.id}
                hover={term.isValid && term.isAvailableInCada}
                onClick={event => handleTermClick(index,event)}
                selected={term.checked}
            >
                <TableCell className={clsx(classes.cell,classes.checkboxCell)} align="left">
                    <Checkbox color="primary" disabled={term.isValid && term.isAvailableInCada ? false : true} checked={term.checked} name={index.toString()} /*onChange={termCheckboxChanged}*/ >
                    </Checkbox>
                </TableCell>
                <TableCell className={clsx(classes.cell,classes.idCell)} align="left">
                    <Typography color={term.isValid && term.isAvailableInCada ? "secondary" : "primary"} style={{color: term.isValid && term.isAvailableInCada ? null : "rgba(0, 0, 0, 0.26)"}} >
                        [{term.id}]
                    </Typography>
                </TableCell>
                <TableCell className={clsx(classes.cell,classes.nameCell)} align="left">
                    <Typography style={{
                        color: term.isValid && term.isAvailableInCada ? "unset" : "rgba(0, 0, 0, 0.26)",
                        display: 'flex',
                        flexFlow: 'row',
                        alignItems: 'center'
                    }}>
                        { term.isValid ? term.name : "invalid term id"} 
                        { term.isValid && term.isAvailableInCada === false ? <Tooltip title="not available for analysis" placement="right"><WarningIcon size="small" style={{ color: 'rgba(251, 140, 0, 0.5)', fontSize: '1.3em', marginLeft: '4px'}} /></Tooltip> : null } 
                        { term.isValid === false ? <Tooltip title="invalid term id" placement="right"><ErrorIcon size="small" style={{ color: 'rgba(198, 40, 40, 0.5)', fontSize: '1.3em', marginLeft: '4px'}} /></Tooltip> : null } 
                    </Typography>
                </TableCell>
            </TableRow>
        )
    }

    function renderTerms() {
        return terms.map( (term,index) => renderTerm(term,index) )
    }

    return (

        <Box display="flex" alignItems="center" style={{...props.style}}>
            <Tooltip title="Import HPO term list" placement="top">
                <IconButton onClick={openDialog}>
                    <LoadIcon/>
                </IconButton>
            </Tooltip>

            <Dialog
                classes={{paper: classes.dialog}}
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle id="form-dialog-title">Import HPO term list</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText style={{marginBottom: '32px'}}>
                        Read multiple HPO terms from any kind of textual input. Term ids must satisfy the format <Typography variant="button" display="inline" color="primary" style={{backgroundColor: '#D9D9D9', padding: '2px 4px', borderRadius: '4px'}}>HP:XXXXXXX</Typography> to be recognized. Click the arrow button to process the text input. Verify the extracted term list and click <Typography variant="button" display="inline" color="primary">apply</Typography> to add the terms to the main list or click <Typography variant="button" display="inline" color="primary">cancel</Typography>  to discard and leave this view.
                    </DialogContentText>
                    <Box display="flex" flexDirection="row" alignItems="stretch" flex="1">
                        <TextField
                            value={inputString}
                            onChange={ event => setInputString(event.target.value) }
                            classes={{root: classes.inputFieldRoot}}
                            style={{width: '30%'}}
                            id="filled-multiline-static"
                            label="HPO term ids"
                            multiline
                            variant="filled"
                        />

                        <Box display="flex" alignItems="center">
                            <Tooltip title="Parse text" placement="top">
                                <Button color="primary" style={{margin: '0px 10px', minWidth: '0px'}} onClick={parseText}>
                                    <ProcessIcon fontSize="large" />
                                </Button>
                            </Tooltip>
                        </Box>

                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table" size="small" dense>
                                { terms.length > 0 ? (
                                    <>
                                        {/* 
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.th} align="left">Rank</TableCell>
                                                <TableCell className={classes.th} align="left">ID</TableCell>
                                                <TableCell className={classes.th} align="left">Symbol</TableCell>
                                                <TableCell className={classes.th} align="left">Score</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        */}

                                        <TableBody>
                                            { renderTerms() }
                                        </TableBody>
                                    </>
                                ) : (
                                        null

                                        /* 
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{border: 'none'}}>
                                                    <Typography
                                                        display="block"
                                                        variant="overline"
                                                        className={classes.emptyMessage}
                                                        align='center'
                                                        style={{minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                                    >
                                                        no entries for current selection
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        */

                                )}
                            </Table>

                        </TableContainer>

                    </Box>

                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button onClick={closeDialog} color="primary">
                        <span style={{paddingTop: '1px', marginRight: '4px'}}>Cancel</span><CancelIcon/>
                    </Button>
                    <Button onClick={applySelection} color="primary" style={{marginLeft: '12px'}}>
                        <span style={{paddingTop: '1px', marginRight: '4px'}}>Apply</span><ApplyIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>



    )
}

