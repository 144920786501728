
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'

import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DetailArrowIcon from '@material-ui/icons/ArrowForward'

import LoadingOverlay from './LoadingOverlay.jsx'

Genes.propTypes = {
    // TODO
}


const useStyles = makeStyles((theme) => ({

    list: {
    },



    container: {
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
        overflow: 'hidden',
        flex: '1'
    },

    tableContainer: {
        overflowY: 'scroll',
        flex: '1'
    },

    table: {
        paddingTop: 0,
        paddingBottom: 0,
        // backgroundColor: '#D9D9D9',

        '& th.MuiTableCell-stickyHeader': {
            backgroundColor: '#D9D9D9'
        }
    },

    cell: {
        // padding: 0,
        // paddingLeft: '16px',
        // paddingTop: '2px',
        // paddingBottom: '2px',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.085)'
    },

    th: {
        padding: 0,
        paddingLeft: '16px',
        paddingTop: '12px',
        paddingBottom: '6px',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.085)'
    },

    pagination: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, 0.42)',

        '& > div.MuiTablePagination-spacer': {
            display: 'none'
        }

    },

    filterField: {
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
            paddingRight: '0px'
        }
    },

    clearFilterButton: {
        marginRight: '4px'
    },

    clearFilterIcon: {
        // marginRight: '4px'
    }

}))


export default function Genes(props) {

    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selected, setSelected] = React.useState(null);
    const [filterQuery, setFilterQuery] = React.useState('');

    const filteredGenes = React.useMemo(() => {
        if (filterQuery === null || filterQuery.length <= 0) {
            return props.genes
        } else {
            // return props.genes.filter( gene => gene.geneId.includes(filterQuery) || gene.geneSymbol.includes(filterQuery) );
            return props.genes.filter(function (gene) {
                let filterTerms = filterQuery.toLowerCase().split(',').map(term => term.trim())
                for (let filterTerm of filterTerms) {
                    if (filterTerm.length > 0 && (gene.geneId.toLowerCase().includes(filterTerm) || gene.geneSymbol.toLowerCase().includes(filterTerm))) {
                        return true
                    }
                }
                return false
            });
        }
    }, [props.genes, filterQuery]);

    React.useEffect(() => {
        setPage(0)
        setSelected(null)
        props.onGeneSelection(null)
    }, [props.genes, filterQuery])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleSelection(geneId, event) {
        if (selected === geneId) {
            setSelected(null)
            props.onGeneSelection(null)
        } else {
            setSelected(geneId)
            props.onGeneSelection(geneId)
        }
    }

    function updateFilterQuery(event) {
        setFilterQuery(event.target.value)
    }

    function clearFilterQuery(event) {
        setFilterQuery('')
    }


    return (
        <Box display='flex' flexDirection='column' alignItems='stretch' className={classes.container} style={{...props.style}}>

            <TextField
                className={classes.filterField}

                fullWidth

                label='Filter (comma separated)'
                value={filterQuery}

                // onFocus={openSearch}
                onChange={updateFilterQuery}

                type="search"
                variant="filled"

                InputProps={{
                    endAdornment: (
                        <IconButton
                            className={classes.clearFilterButton}
                            edge="end"
                            aria-label="delete"
                            onClick={clearFilterQuery}
                        >
                            <CancelIcon
                                className={classes.clearFilterIcon}
                            />
                        </IconButton>
                    )
                }}
            />


            {filteredGenes.length > 0 ? (
            <TableContainer className={classes.tableContainer} style={{overflowY: filteredGenes.length > 0 ? 'scroll' : 'hidden'}}>
                <Table className={classes.table} aria-label="simple table" size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.th} align="left">Rank</TableCell>
                            <TableCell className={classes.th} align="left">ID</TableCell>
                            <TableCell className={classes.th} align="left">Symbol</TableCell>
                            <TableCell className={classes.th} align="left">Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredGenes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((gene) => (
                            <TableRow
                                hover
                                key={gene.geneId}
                                onClick={event => handleSelection(gene.geneId, event)}
                                selected={selected === gene.geneId}
                            >
                                <TableCell className={classes.cell} align="left">#{gene.rank}</TableCell>
                                <TableCell className={classes.cell} align="left">{gene.geneId}</TableCell>
                                <TableCell className={classes.cell} align="left">{gene.geneSymbol}</TableCell>
                                <TableCell className={classes.cell} align="left">
                                    <b>{gene.score.toFixed(4)}</b>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            ) : (
                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' style={{flex: '1'}}>
                    <Typography
                        display="block"
                        variant="overline"
                        className={classes.emptyMessage}
                    >
                        empty list
                    </Typography>
                </Box>
            )}

            {filteredGenes.length > 0 ? (
                <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 15, 20, 50]}
                    component="div"
                    count={filteredGenes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            ) : (null)}

            <LoadingOverlay open={props.isLoading} />

        </Box>
    )
}

