import React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({

}));

export default function Publications() {

  const classes = useStyles()

  return (
    <Box style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="button">
          to do
      </Typography>
    </Box>
  )

}

