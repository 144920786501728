
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
//import DeleteHPOIcon from '@material-ui/icons/DeleteTwoTone'
//import DeleteHPOIcon from '@material-ui/icons/Clear'
//import DeleteHPOIcon from '@material-ui/icons/CancelTwoTone'
import DeleteHPOIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'



HpoTerms.propTypes = {
    // TODO
}


const useStyles = makeStyles((theme) => ({

    list: {
        paddingTop: 0,
        paddingBottom: 0,
        // border: '1px solid red', 
        backgroundColor: '#D9D9D9',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        overflow: 'auto'
    },

    listItem: {
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'flex-start'
    },

    cancelListItem: {
        marginTop: '4px',
        marginBottom: '4px',
        minWidth: 'unset'
    },

    cancelButton: {
        padding: '12px',
        marginTop: '-12px',
        marginBottom: '-12px'
    }

}))


export default function HpoTerms(props) {

    const classes = useStyles();

    function renderTerm(term) {

        let name = props.hpoData[term].name

        return (
            <ListItem
                key={term}
                className={classes.listItem}
            >
                <ListItemText style={{ flexGrow: 0, marginRight: '6px', minWidth: 'unset'}}>
                    <Typography color="secondary">[{term}]</Typography>
                </ListItemText>
                <ListItemText>
                    <Typography>{name}</Typography>
                </ListItemText>
                <ListItemIcon className={classes.cancelListItem}>
                    <Tooltip title="Remove" placement="right">
                        <IconButton
                            className={classes.cancelButton}
                            edge="end"
                            aria-label="delete"
                            onClick={() => props.handleRemoveEvent(term)}
                        >
                            <DeleteHPOIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
                {/*
                <ListItemSecondaryAction>
                    <Tooltip title="Remove" placement="right">
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => props.handleRemoveEvent(term)}
                        >
                            <DeleteHPOIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
                */}
            </ListItem>
        )
    }


    function renderTerms() {
        const terms = []
        props.terms.map(term => {
            terms.push(renderTerm(term))
            return null
        })
        return terms
    }


    return (
        <List className={classes.list} dense style={{...props.style, paddingTop: '4px', paddingBottom: '4px'}}>
            {props.terms.length > 0 ? (
                renderTerms()
            ) : (
                <ListItem key="empty" style={{minHeight: '300px'}}>
                    <ListItemText
                        // style={{ marginTop: '100px', marginBottom: '100px' }}
                    >
                        <Typography
                            display="block"
                            variant="overline"
                            className={classes.emptyMessage}
                            align='center'
                        >
                            empty list
                        </Typography>
                    </ListItemText>
                </ListItem>
                )}
        </List>
    )
}

