import React from 'react'
// import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import List from '@material-ui/core/List';

import SearchIcon from '@material-ui/icons/Search'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box'
import { EventNoteRounded, RoomSharp } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({

    list: {
        paddingTop: 0,
        paddingBottom: 0
    },

    pagination: {
        // border: '1px solid red',
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center'
    },

    listHeader: {
        paddingLeft: '16px',
        // borderBottom:'1px solid #DDD',
        // paddingBottom: '2px',
        // marginBottom: '6px',
        // border: '1px solid green'
    },

    bottomContent: {
        paddingLeft: '16px',
        marginTop: '10px'
    },

    topSeparator: {
        marginTop: '6px',
        marginBottom: '6px',
        borderBottom:'1px solid #DDD'
    },

    bottomSeparator: {
        marginTop: '6px',
        marginBottom: '6px',
        borderBottom:'1px solid #DDD'
    },

    emptyMessage: {
        paddingLeft: '16px',
    },

    searchField: {
        // backgroundColor: 'red',

        '& label': {
            marginLeft: '4px'
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
            paddingRight: '16px'
        }
    }
}));


export default function SearchSelect(props) {

    const classes = useStyles();

    const searchFieldRef = useRef(null);

    const [query, setQuery] = React.useState('');
    const [anchor, setAnchor] = React.useState(null);

    const [page, setPage] = React.useState(1)

    const [keyCursorActive, setKeyCursorActive] = React.useState(true)
    const [keyCursor, setKeyCursor] = React.useState(null)
    const [lastKeyCursor, setLastKeyCursor] = React.useState(null)
    


    useEffect(() => {
        setPage(1)
        setKeyCursor(null)
     }, [props.items]);

    useEffect(() => {
        closeSearch()
        setKeyCursor(null)
        setKeyCursorActive(true)
    }, [props.fullReset]);

    useEffect(() => {
        setKeyCursor(null)
        setKeyCursorActive(true)
        setQuery('')
        props.filter('')
    }, [props.softReset]);

    useEffect(() => {
        if(keyCursor != null) {

            setPage(Math.floor((keyCursor/props.itemsPerPage)+1))
            // console.log("SET PAGE " + )
        }
    }, [keyCursor]);


    function updateSearch(event) {
        let queryValue = event.target.value
        setQuery(queryValue)
        props.filter(queryValue)
    }

    function closeSearch() {
        setAnchor(null);
        setQuery('')
        props.filter('')
    }

    function openSearch(event) {
        setAnchor(searchFieldRef.current)
    }

    function handleKeyDown(event) {
        if(keyCursorActive == false) return
        if(event.keyCode === 13) {
            if(keyCursor != null && props.onKeySelect) {
                props.onKeySelect(keyCursor)
            }
        } else if(event.keyCode === 38) {
            if(keyCursor != null && keyCursor > 0) {
                setKeyCursor(keyCursor-1)
                event.preventDefault()
            }
        } else if(event.keyCode === 40) {
            if(keyCursor == null) {
                if(props.items.length > 0) {
                    setKeyCursor(0)
                    event.preventDefault()
                }               
            } else {
                if(keyCursor < props.items.length-1) {
                    setKeyCursor(keyCursor+1)
                    event.preventDefault()
                }
            }
        }
    }


    function activateMouseCursor() {
        setKeyCursorActive(false)
        setLastKeyCursor(keyCursor)
        setKeyCursor(null)
    }

    function activateKeyCursor() {
        setKeyCursor(lastKeyCursor)
        setKeyCursorActive(true)
    }




    function renderItems() {

        const items = []
        let i = (page - 1) * props.itemsPerPage

        for (let j = 0; j < props.itemsPerPage; j++) {
            if (i + j < props.items.length) {
                items.push(props.renderItem(props.items[i + j], i+j === keyCursor))
            }
            else {
                items.push(props.renderSpacerItem())
            }
        }

        return items
    }




    function onPaginationChange(event, page) {
        setPage(page)
    }




    return (

        <ClickAwayListener onClickAway={closeSearch}>

            <Box style={{...props.style}}>
                <TextField
                    ref={searchFieldRef}
                    className={classes.searchField}

                    fullWidth

                    label='Search'
                    value={query}

                    onFocus={openSearch}
                    onChange={updateSearch}
                    onKeyDown={handleKeyDown}

                    type="search"
                    variant="filled"

                    InputProps={{
                        endAdornment: (<SearchIcon/>)
                    }}
                />

                <Popper
                    className={classes.popper}
                    style={{ width: anchor ? anchor.clientWidth - 16 : null, marginTop:'8px' }}
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    placement='bottom'
                    // role={undefined}
                    // transition
                    // disablePortal
                    modifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: false
                        },
                        arrow: {
                          enabled: false,
                        },
                      }}
                >

                    <Paper elevation={4}>
                        {props.items.length > 0 ? (
                            <Box p={1}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography display="block" variant="overline" className={classes.listHeader}>
                                        {props.items.length} entries
                                    </Typography>
                                    <Pagination
                                        className={classes.pagination}
                                        count={Math.ceil(props.items.length / props.itemsPerPage)}
                                        // variant="outlined"
                                        // shape="rounded"
                                        color="primary"
                                        size="small"
                                        onChange={onPaginationChange}
                                        page={page}
                                    />
                                </Box>
                                <Box className={classes.topSeparator}/>
                                <List
                                    className={classes.list}
                                    dense
                                    onMouseOver={activateMouseCursor}
                                    onMouseOut={activateKeyCursor}
                                >
                                    {renderItems()}
                                </List>
                                <Box className={classes.bottomSeparator}/>
                                <Box className={classes.bottomContent}>
                                    {props.bottomContent}
                                </Box>
                            </Box>
                        ) : (
                            <Box p={1} style={{textAlign: 'center'}}>
                                <Typography
                                    display="block"
                                    variant="overline"
                                    className={classes.emptyMessage}
                                >
                                    {query.length >= 3 ? "No entries found" : "Search query to short"}
                                </Typography>
                            </Box>
                        )}
                    </Paper>

                </Popper>

            </Box>

        </ClickAwayListener>


    )


}







