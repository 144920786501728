import React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route, NavLink, useLocation } from "react-router-dom";

import './Layout.css';

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import ListItemIcon from '@material-ui/core/ListItemIcon';

import HomeIcon from '@material-ui/icons/Home';
import WebserviceIcon from '@material-ui/icons/PlayArrow';
import PublicationIcon from '@material-ui/icons/LibraryBooks';
import HelpIcon from '@material-ui/icons/Help';

import Home from './Home';
import Webservice from './Webservice';
import WebserviceAlt from './WebserviceAlt';
import Publications from './Publications';
import Datenschutz from './Datenschutz';
import Impressum from './Impressum';
import Instructions from './Instructions';

const useStyles = makeStyles((theme) => ({

  header: {
    width: '100%',
    // height: '10rem',
    // backgroundColor: '#ABCDEF',
    backgroundColor: '#DDD',
    padding: '20px 20px',
    boxSizing: 'border-box',

    display: 'flex',
    flexFlow: 'row',
  },

  title: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px dashed rgba(0,0,0,0.3)'
  },

  icons: {
    flex: 0,
    display: "flex",
    flexFlow: "row",
    alignItems: "center",

    '& a': {
      marginLeft: "20px",
      display: 'flex',
      outline: 0,

      '& img': {
        outline: 0,
      }
    }
  },

  navContainer: {
    // backgroundColor: '#314252',
    backgroundColor: '#763872',

  },



  nav: {
    display: 'flex',
    flexFlow: 'row',
    padding: '12px'
  },

  navItem: {
    borderRadius: '5px',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    },
  },


  navLink: {
    outline: 0,
    marginRight: '8px',       
    textDecoration: 'none'
  },

  navLinkActive: {
    // borderBottom: '2px solid rgba(255,255,255,0.85)',

    '& $navItem': {
      backgroundColor: 'rgba(255,255,255,0.1)'
    }
  },

  navIcon: {
    padding: 0,
    minWidth: 0,
    marginRight: '4px',
    color: 'rgba(255,255,255,0.85)'
  },

  navLabel: {
    padding: 0,
    minWidth: 0,
    fontSize: '1rem',
    marginTop: '1px', 
    color: 'rgba(255,255,255,0.85)'
  },


  content: {
    backgroundColor: '#EEE',
    flex: 1,
    overflowY: 'auto',
    //padding: '24px',
    padding: '24px 36px',
    display: 'flex',
    flexFlow: 'column'
  },


  footer: {
    // backgroundColor: '#314252',
    backgroundColor: '#763872',
    padding: '8px',
    color: 'rgba(255,255,255,0.85)'
    // backgroundColor: '#DDD',
    // borderTop: '4px solid #314252'
  }




  /*
  contentContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    backgroundColor: '#EEE',
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '5px'
  }
  */
}));

export default function Layout(props) {

  const classes = useStyles()

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#00897b',
      },
    },
  });

  // const location = useLocation();

  return (

    <Router>
      <ThemeProvider theme={theme}>

        <Box className={classes.header}>
          
          <div className={classes.title}>
            <Typography variant="h4">
                Yield<span style={{color: '#763872'}}>Pred</span>
                <span style={{
                    color: '#00897b',
                    fontSize: '1.0rem',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: '400',
                    lineHeight: '2.66',
                    letterSpacing: '0.08333em',
                    textTransform: 'uppercase'
                  }}>.WEBSERVICE</span>
            </Typography>
            {/* 
            <Typography variant='overline' style={{marginTop: '-8px'}}>
              gene prioritization from <b style={{fontSize:"1.15em"}}>c</b>ase <b style={{fontSize:"1.15em"}}>a</b>nnotations and <b style={{fontSize:"1.15em"}}>d</b>isorder <b style={{fontSize:"1.15em"}}>a</b>nnotations
            </Typography>
            */}
          </div>

          <div className={classes.icons}>
            <a href="https://www.gene-talk.de" target="_blank">
              <img src="genetalk.png"/>          
            </a>
            <a href="https://www.igsb.uni-bonn.de/" target="_blank">
              <img src="igsb.png"/>
            </a>
          </div>
          
        </Box>

        <Box className={classes.navContainer}>
          <MenuList className={classes.nav}>
            <NavLink to='/home' className={classes.navLink} activeClassName={classes.navLinkActive}>
              <MenuItem className={classes.navItem}>
                <ListItemIcon className={classes.navIcon}><HomeIcon/></ListItemIcon>
                <Typography className={classes.navLabel} variant="button">
                  Home
                </Typography>
              </MenuItem>
            </NavLink>
            <NavLink to='/webservice' className={classes.navLink} activeClassName={classes.navLinkActive}>
              <MenuItem className={classes.navItem}>
                <ListItemIcon className={classes.navIcon}><WebserviceIcon/></ListItemIcon>
                <Typography className={classes.navLabel} variant="button">
                  Webservice
                </Typography>
              </MenuItem>
            </NavLink>
            {/* 
            <NavLink to='/publications' className={classes.navLink} activeClassName={classes.navLinkActive}>
              <MenuItem className={classes.navItem}>
                <ListItemIcon className={classes.navIcon}><PublicationIcon/></ListItemIcon>
                <Typography className={classes.navLabel} variant="button">
                  Publications
                </Typography>
              </MenuItem>
            </NavLink>
            */}
            <NavLink to='/instructions' className={classes.navLink} activeClassName={classes.navLinkActive}>
              <MenuItem className={classes.navItem}>
                <ListItemIcon className={classes.navIcon}><HelpIcon/></ListItemIcon>
                <Typography className={classes.navLabel} variant="button">
                  How to use
                </Typography>
              </MenuItem>
            </NavLink>
          </MenuList>
          {JSON.stringify(props.location,null,2)}
        </Box>

        <Box className={classes.content}>
          <Switch>

            <Route path="/webservice_alt">
              <WebserviceAlt/>
            </Route>
            <Route path="/webservice">
              <Webservice/>
            </Route>
            <Route path="/yieldpred">
              <Webservice/>
            </Route>
            <Route path="/instructions">
              <Instructions/>
            </Route>
            {/*
            <Route path="/publications">
              <Publications/>
            </Route>
            */}            
            <Route path="/impressum">
              <Impressum/>
            </Route>
            <Route path="/datenschutz">
              <Datenschutz/>
            </Route>
            <Route path="/home">
              <Home/>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </Box>

        <Box className={classes.footer}>
          <center style={{color: "rgba(255,255,255,0.85)", textDecoration: "none", fontWeight: "bold"}}>
            <NavLink to='/impressum' style={{color: "rgba(255,255,255,0.85)", textDecoration: "none", fontWeight: "bold"}}>
              <Typography variant='overline'>
                <b>Impressum</b>
              </Typography>
            </NavLink>
            &nbsp;|&nbsp;
            <NavLink to='/datenschutz' style={{color: "rgba(255,255,255,0.85)", textDecoration: "none", fontWeight: "bold"}}>
              <Typography variant='overline'>
                <b>Datenschutz</b>
              </Typography>
            </NavLink>
          </center>
        </Box>

      </ThemeProvider>
    </Router>

  )

}

