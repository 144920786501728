import React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import './App.css';

// import clsx from 'clsx'
// import { withStyles } from '@material-ui/styles';
// import IconButton from '@material-ui/core/IconButton'
// import Input from '@material-ui/core/Input'
// import FilledInput from '@material-ui/core/FilledInput'
// import OutlinedInput from '@material-ui/core/OutlinedInput'
// import InputLabel from '@material-ui/core/InputLabel'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FormHelperText from '@material-ui/core/FormHelperText'
// import FormControl from '@material-ui/core/FormControl'
// import TextField from '@material-ui/core/TextField'
// import Visibility from '@material-ui/icons/Visibility'
// import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import SearchIcon from '@material-ui/icons/Search'

// import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

// import Paper from '@material-ui/core/Paper';
// import Box from '@material-ui/core/Box'
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

// import Avatar from '@material-ui/core/Avatar';
// import Chip from '@material-ui/core/Chip';
// import FaceIcon from '@material-ui/icons/Face';
// import DescriptionIcon from '@material-ui/icons/Description';


import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'


import HpoSelect from './components/HpoSelect.jsx'
import HpoTerms from './components/HpoTerms.jsx'
import Genes from './components/Genes.jsx'


import decompress from 'brotli/decompress'
import utf8, { getCharCode } from 'utf-8'

import lunr from 'lunr'
import { Typography } from '@material-ui/core';
import { number } from 'prop-types';
import { FullscreenExit } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex'
  },

  header: {
    width: '100%',
    height: '10rem',
    backgroundColor: '#ABCDEF'
  },

  menu: {
    backgroundColor: '#FEDCBA'
  }



  /*
  contentContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    backgroundColor: '#EEE',
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '5px'
  }
  */
}));

export default function App() {

  const classes = useStyles()

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#00897b',
      },
    },
  });

  const [isReady, setReady] = useState(false)
  const [hpoData, setHpoData] = useState(null)
  const [hpoIndex, setHpoIndex] = useState(null)
  const [hpoTerms, setHpoTerms] = useState([])
  const [genes, setGenes] = useState([])
  const [loadingGenes, setLoadingGenes] = useState(false)
  const [showCadaNotAvailableError, setShowCadaNotAvailableError] = useState(false)

  useEffect(() => { loadHpo() }, [])


  function loadHpo() {

    console.log("LOADING HPO DATA ...")
    console.time("time")

    fetch("hpo.compressed.bin").then(async (response) => {
      if (response.ok) {
        let compressed = Buffer.from(await response.arrayBuffer())
        let bytes = decompress(compressed)
        let hpo = JSON.parse(utf8.getStringFromBytes(bytes))

        setHpoData(hpo.data)
        setHpoIndex(lunr.Index.load(hpo.index))

        setReady(false)
        // console.log(JSON.stringify(hpo.data,null,2))
        // Object.keys(hpo.data).map( ([key,value]) => { console.log(key) } )
        // console.log(Object.entries(hpo.data).map( ([key,value]) => console.log(key + ' -> ' + value) ))
      }
      else {
        throw new Error("Could not fetch HPO data [HTTP STATUS CODE " + response.status + "]")
      }
    })
      .catch((error) => {
        console.log(error)
      })

    console.timeEnd("time")
  }


  function updateGenes(hpoTerms) {

    setLoadingGenes(true)

    /*
    const response = await fetch(/api/time, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    */



    fetch('/api/process', {
    // fetch('localhost:5000/api/process', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(hpoTerms)
    })
    .then(response => response.json())
    .then(
      data => {
        console.log(JSON.stringify(data,null,2))
        setGenes(data)
        setLoadingGenes(false)
      }
    )

    /*
    fetch('/api/time')
      .then(response => response.json())
      .then(
        data => {
          console.log(JSON.stringify(data, null, 2))
        }
      );
      */

    // setTimeout(() => setLoadingGenes(false), 2000)

    // 2. ergebnisse setzten als genes

    // console.log("<LFKKLEJLFJLKWEJFKLWEJFK " + JSON.stringify(hpoTerms))

  }



  function handleHpoSelect(term) {
    if (hpoTerms.includes(term) == false) {

      // HIER EINEN DIALOG, WENN ES NICHT IN CADA IST
      setShowCadaNotAvailableError(true)



      let updated = hpoTerms.concat(term)
      updateGenes(updated)
      setHpoTerms(updated)
    }
  }


  function handleHpoRemove(term) {
    let updated = hpoTerms.filter(value => value !== term)
    updateGenes(updated)
    setHpoTerms(updated)
  }


  function hideCadaNotAvailableError() {
    setShowCadaNotAvailableError(false)
  }



  return (

    <ThemeProvider theme={theme}>
      <Container className={classes.contentContainer} maxWidth="lg" >

        <Box style={{
          marginBottom: theme.spacing(4),
          paddingBottom: theme.spacing(1.6),
          borderBottom: '1px dashed #CCC',
        }}>
          <Typography display='block' align='center' variant="h4">
            CADA Webservice <span style={{color: '#00897b'}}>Prototype</span>
          </Typography>
        </Box>

        <Grid container spacing={6} style={{ flex: 1 }}>
          <Grid
            item xs={6}
            style={{ display: 'flex', flexFlow: 'column' }}
          >
            <Typography
              display='block'
              align='left'
              variant='overline'
              gutterBottom
              style={{paddingLeft: '16px'}}
            >
              Human Phenotypes
            </Typography>
            <HpoSelect
              // style={{flex: 0}}
              isReady={isReady}
              hpoData={hpoData}
              hpoIndex={hpoIndex}
              handleSelectEvent={handleHpoSelect}
            />
            <HpoTerms
              // style={{flex: 1}}
              hpoData={hpoData}
              terms={hpoTerms}
              handleRemoveEvent={handleHpoRemove}
            />
          </Grid>
          <Grid item xs={6} style={{ borderLeft: '1px dashed #CCC' }}>
            <Typography
              display='block'
              align='left'
              variant='overline'
              gutterBottom
              style={{paddingLeft: '16px'}}
            >
              Associated Genes
            </Typography>
            <Genes
              genes={genes}
              isLoading={loadingGenes}
            />
          </Grid>
        </Grid>

      </Container>

    </ThemeProvider>

 

  )

}

