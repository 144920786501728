import React from 'react'
import { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ClearAllButton from './ClearAllButton.jsx'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'

const clearMarginPadding = {
    padding: '0px',
    margin: '0px'
}

const useStyles = makeStyles((theme) => ({

    container: {
        '& > *:not(:first-child)': {
            marginLeft: '8px'
        }
    },

    dropdown: {
        // width: 200,
        flex: 1,
        /*
        '&:not(:first-child)': {
            marginLeft: '8px'
        }
        */
    }
}));

export default function OptionalInput(props) {

    const classes = useStyles()

    /*
    const [sex, setSex] = React.useState('')
    const [age, setAge] = React.useState('')

    const handleSexChange = (event) => {
        setSex(event.target.value)
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value)
    };

    const clearAll = (event) => {
        setSex('')
        setAge('')
    }
    */

    const handleSexChange = (event) => {
        props.handleSexChange(event.target.value)
    };

    const handleAgeChange = (event) => {
        props.handleAgeChange(event.target.value)
    };

    return (

        <Box display="flex" flexDirection="row" alignItems="center" className={classes.container} style={{ ...props.style }}>

            <FormControl variant="filled" className={classes.dropdown}>
                <InputLabel id="sex-select-label">Sex</InputLabel>
                <Select
                    labelId="sex-select-label"
                    id="sex-select"
                    value={props.values.sex}
                    onChange={handleSexChange}
                >
                    <MenuItem value={''}><em>Undisclosed</em></MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'male'}>Male</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="filled" className={classes.dropdown}>
                <InputLabel id="age-select-label">Age</InputLabel>
                <Select
                    labelId="age-select-label"
                    id="age-select"
                    value={props.values.age}
                    onChange={handleAgeChange}
                >
                    <MenuItem value={''}><em>Undisclosed</em></MenuItem>
                    <MenuItem value={'child'}>Child</MenuItem>
                    <MenuItem value={'adult'}>Adult</MenuItem>
                </Select>
            </FormControl>

            <ClearAllButton clickHandler={props.clearAll} />

        </Box>
    )
}

