
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import LaunchIcon from '@material-ui/icons/Launch'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'


const useStyles = makeStyles((theme) => ({

    omimContainer: {
        padding: "8px",
        paddingTop: "16px"
    },

    omimContent: {
        // backgroundColor: "#ACC",
        // backgroundColor: "rgba(0, 137, 123, 0.08)",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        border: "1px solid rgba(0,0,0,0.2)",
        padding: "10px",
        borderRadius: "3px"
    },

    omimLinkContainer: {
        padding: '4px',
        marginLeft: '20px',
        // backgroundColor: 'rgba(255,255,255,0.2)',
        // marginBottom: "2px",
        border: "3px"
    },

    omimLink: {
        fontSize: '0.875rem'
    },

    diagramContainer: {
        padding: "14px 8px",
        height: "300px"
    },

    diagramContent: {
        height: "100%",
        position: "relative",
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "5px"
    },

    diagramBar: {
        position: "absolute",
        border: "1px solid #888",
    },

    baseline: {
        // borderBottom: "1px dashed rgba(0, 0, 0, 0.2)",
        borderBottom: "1px dashed #CCC",
        top: "50%",
        left: "0%",
        width: "100%",
        height: "0px",
        position: "absolute"
    },

    th: {
        fontWeight: 'normal',
        border: "none",
        paddingBottom: "0px"
    },

    headerCell: {
        fontWeight: 'bold',
        // borderBottom: '1px dashed rgba(0, 0, 0, 0.085)',
        border: "none",
        paddingBottom: "0px"
    },

    tableCell: {
        fontWeight: 'bold',
        padding: "4px 0px",
        border: "none",
   },

    idCell: {
        whiteSpace: 'nowrap',
        width: '1%',
        paddingLeft: "10px",
        verticalAlign: "top"
    },

    nameCell: {
        paddingLeft: "4px"
    },

    scoreCell: {
        whiteSpace: 'nowrap',
        width: '1%',
        paddingRight: "22px",
        verticalAlign: "top"
    },

    legendCell: {
        width: "1%",
        paddingLeft: "22px",
        verticalAlign: "top"
    },

    tableContainer: {
        overflow: 'auto'
    }

}))







 





 
export default function DetailView(props) {

    const classes = useStyles();

    const colors = ["f6c85f", "9dd866", "ffa056", "8dddd0", "FCAB64", "B9314F", "0b84a5", "6f4e7c", "FCD29F", "A1FCDF", "ca472f", "208AAE", "4C956C", "5DD39E", "3185FC", "F9DC5C", "A47963", "7B5E7B", "A6A57A", "A33B20", "7B5E7B", "E58F65", "D05353", "1B998B", "E1BB80", "92AFD7", "90A9B7", "EDD382", "2E5266", "6E8898", "9FB1BC", "F2F3AE", "4E6766", "2292A4", "BDBF09", "DB5461", "84A9C0", "424874", "348AA7", "6E9075", "81F0E5", "AD7A99", "BD4089", "57A773", "9BD1E5", "CFA5B4", "A7E8BD", "F7D4BC"]

    const [focus, setFocus] = React.useState(null);

    function renderDiagram() {

        let max = 0
        for(let v of props.gene.partitions) {
            if(Math.abs(v) > max) {
                max = Math.abs(v)
            }
            // console.log("v: " + v + ", max: " + max) 
        }
        // console.log("MAX: " + max)

        max *= 1.1

        let r = 0.35
        let n = props.gene.partitions.length
        let w = 100.0 / ( (n*r) + ((n+1)*(1-r)) )
        let x = (1.0-r) * w

        let bars = []
        for(let v of props.gene.partitions) {
            let h = 50.0 * Math.abs(v) / max
            bars.push({
                x: x,
                width: r*w,
                y: v < 0 ? 50.0 : 50.0 - h,
                height: h,
                v: v
            })
            x += w
        }

        return (
            <div className={classes.diagramContainer}>
                <div className={classes.diagramContent}>
                    <div className={classes.baseline}></div>
                    { bars.map( (bar,i) =>
                        <div
                            className={classes.diagramBar}
                            style={{
                                height: bar.height+"%",
                                width: bar.width+"%",
                                top: bar.v > 0 ? "calc(" + bar.y + "% - 1px)" : bar.y+"%",
                                left: bar.x+"%",
                                opacity: focus == null || focus == i ? "1.0" : "0.2",
                                backgroundColor: "#"+colors[i%colors.length]
                            }}
                            onMouseOver = { () => setFocus(i) }
                            onMouseOut = { () => setFocus(null) }
                        >
                        </div>
                    )}
                </div>
            </div>
        )
    }




    function renderOmimLinks() {

        return (

            <div className={classes.omimContainer}>
                <div className={classes.omimContent}>
    
                    { props.gene.omim != null ? (
                        <>
                            <Typography display="block" style={{fontSize: '0.875rem', fontWeight: 'bold'}}>OMIM Gene Entry:</Typography>
                            <div className={classes.omimLinkContainer}>
                                <Link className={classes.omimLink} href={"https://www.omim.org/entry/" + props.gene.omim.id} target="_blank">
                                    {props.gene.geneSymbol} ({props.gene.omim.id})
                                </Link>
                            </div>
                            <Typography display="block" style={{marginTop: '10px', fontSize: '0.875rem', fontWeight: 'bold'}}>OMIM Phenotype Entries:</Typography>
                            { props.gene.omim.phenotypes.map( phenotype => 
                                <div className={classes.omimLinkContainer}>
                                    <Link className={classes.omimLink} href={"https://www.omim.org/entry/" + phenotype.id} target="_blank">
                                        {phenotype.title} ({phenotype.id})
                                    </Link>
                                </div>
                            )}
                        </>
                        ) : ( 
                        <span>
                            <Typography display="block" style={{fontSize: '0.875rem', fontWeight: 'bold'}}>OMIM Query:</Typography>
                            <div className={classes.omimLinkContainer}>
                                <Link className={classes.omimLink} href={"https://www.omim.org/search?search=" + props.gene.geneSymbol} target="_blank">
                                    Search for {props.gene.geneSymbol}
                                </Link>
                            </div>

                            {/*
                            <Typography
                                display="block"
                                variant="overline"
                                align='center'
                                style={{color: "rgba(0,0,0,0.3)", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                                no omim links available
                            </Typography>
                            */}
                        </span>
                    )}
    
                </div>
            </div>

        )
    }





    return (

        props.gene ? (
            <>
                <Table className={classes.header} aria-label="simple table" size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.th} align="left">Rank</TableCell>
                            <TableCell className={classes.th} align="left">ID</TableCell>
                            <TableCell className={classes.th} align="left">Symbol</TableCell>
                            <TableCell className={classes.th} align="right">Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={props.gene.geneId}>
                            <TableCell className={classes.headerCell} align="left">#{props.gene.rank}</TableCell>
                            <TableCell className={classes.headerCell} align="left">{props.gene.geneId}</TableCell>
                            <TableCell className={classes.headerCell} align="left">
                                {props.gene.geneSymbol}
                                {/*
                                <Link href={"https://www.ncbi.nlm.nih.gov/gene/" + props.gene.geneId.substring(7)} target="_blank">
                                    <IconButton color="primary" component="span" size="small" style={{marginTop: "-5px", padding: "0px", marginLeft: "4px"}}>
                                        <LaunchIcon fontSize="inherit"/>
                                    </IconButton> 
                                </Link>
                                */}
                            </TableCell>
                            <TableCell className={classes.headerCell} align="right">{props.gene.score.toFixed(4)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                { renderDiagram() }

                <Box className={classes.tableContainer}>

                <Table className={classes.header} aria-label="simple table" size="small" >
                    <TableBody>
                        { props.gene.partitions.map( (v,i) =>
                            <TableRow
                                key={props.gene.geneId + props.hpoTerms[i]}
                                onMouseOver = { () => setFocus(i) }
                                onMouseOut = { () => setFocus(null) }
                                // style = {{
                                //     backgroundColor: i == focus ?   : null
                                // }}
                            >
                                <TableCell className={clsx(classes.tableCell,classes.legendCell)} align="left">
                                    <div style={{height: "21px", display: "flex", flexFlow: "column", justifyContent: "center"}}>
                                        <div style={{
                                            border: "1px solid #888",
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: "#" + colors[i%colors.length]
                                        }}>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell,classes.idCell)} align="left">
                                    <Typography
                                        color="secondary"
                                        size="small"
                                        style={{
                                            fontSize: "1em",
                                            fontWeight: i == focus ? "bold" : null
                                        }}>
                                        [{props.hpoTerms[i]}]
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    className={clsx(classes.tableCell,classes.nameCell)}
                                    align="left"
                                >
                                    <Typography style={{
                                        display: 'flex',
                                        flexFlow: 'row',
                                        alignItems: 'center',
                                        fontSize: "1em",
                                        fontWeight: i == focus ? "bold" : null
                                    }}>
                                        { props.hpoData[props.hpoTerms[i]].name }
                                    </Typography>
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell,classes.scoreCell)}>
                                    <Typography style={{
                                            display: 'flex',
                                            flexFlow: 'row',
                                            justifyContent: 'end',
                                            fontSize: "1em",
                                            fontWeight: i == focus ? "bold" : null
                                        }}>
                                        {v.toFixed(4)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                </Box>

                { renderOmimLinks() }

            </>


        ) : (
            <Typography
                display="block"
                variant="overline"
                className={classes.emptyMessage}
                align='center'
                style={{minHeight: '352px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                NO GENE SELECTED
            </Typography>
        )



    )
}

