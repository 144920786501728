import React from 'react'
import { useEffect } from 'react'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'

export default function ClearAllButton(props) {

    return (
        <Box display="flex" alignItems="center" style={{...props.style}}>
            <Tooltip title="Clear All" placement="top">
                <IconButton onClick={props.clickHandler}>
                    <ClearIcon/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

