import React from 'react'
import { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

import blue from '@material-ui/core/colors/blue'

import './Webservice.css';

// import clsx from 'clsx'
// import { withStyles } from '@material-ui/styles';
// import IconButton from '@material-ui/core/IconButton'
// import Input from '@material-ui/core/Input'
// import FilledInput from '@material-ui/core/FilledInput'
// import OutlinedInput from '@material-ui/core/OutlinedInput'
// import InputLabel from '@material-ui/core/InputLabel'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FormHelperText from '@material-ui/core/FormHelperText'
// import FormControl from '@material-ui/core/FormControl'
// import TextField from '@material-ui/core/TextField'
// import Visibility from '@material-ui/icons/Visibility'
// import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import SearchIcon from '@material-ui/icons/Search'

// import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

// import Paper from '@material-ui/core/Paper';
// import Box from '@material-ui/core/Box'
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

// import Avatar from '@material-ui/core/Avatar';
// import Chip from '@material-ui/core/Chip';
// import FaceIcon from '@material-ui/icons/Face';
// import DescriptionIcon from '@material-ui/icons/Description';


import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'


import HpoSelect from './components/HpoSelect.jsx'
import HpoListImport from './components/HpoListImport.jsx'
import ClearAllButton from './components/ClearAllButton.jsx'
import HpoTerms from './components/HpoTerms.jsx'
import Genes from './components/Genes.jsx'
import DetailView from './components/DetailView.jsx'
import OptionalInput from './components/OptionalInput'
import DiagnosticYield from './components/DiagnosticYield'


import decompress from 'brotli/decompress'
import utf8, { getCharCode } from 'utf-8'

import lunr from 'lunr'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  sectionTitle: {
    /* marginBottom: '4px', */
    marginLeft: '4px'
  },

  sectionMargin: {
    marginTop: '40px'
  },

  searchBox: {
    backgroundColor: '#D9D9D9',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },

  detailView: {
    backgroundColor: '#D9D9D9',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  }

}));

export default function WebserviceNeu() {

  const classes = useStyles()

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#00897b',
      },
    },
  });

  const [isReady, setReady] = useState(false)
  const [hpoData, setHpoData] = useState(null)
  const [hpoIndex, setHpoIndex] = useState(null)
  const [hpoTerms, setHpoTerms] = useState([])
  const [genes, setGenes] = useState([])
  const [loadingGenes, setLoadingGenes] = useState(false)
  const [selectedGene, setSelectedGene] = useState(null)
  const [dyParameter, setdyParameter] = useState({ sex: '', age: '' })
  const [dyPlots, setDyPlots] = useState({ 'solved': null, 'not_solved': null })
  const [dyValue, setDyValue] = useState(null)
  const [dyHelpDialogOpen, setDyHelpDialogOpen] = useState(false);

  useEffect(() => {
    loadHpo()
    loadDyPlots()
  }, [])


  function loadDyPlots() {
    fetch('/api/getDyPlots', { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setDyPlots(data)
      })
  }


  function loadHpo() {

    console.log("LOADING HPO DATA ...")
    console.time("time")

    fetch("hpo.compressed.bin").then(async (response) => {
      if (response.ok) {
        let compressed = Buffer.from(await response.arrayBuffer())
        let bytes = decompress(compressed)
        let hpo = JSON.parse(utf8.getStringFromBytes(bytes))

        setHpoData(hpo.data)
        setHpoIndex(lunr.Index.load(hpo.index))

        setReady(false)
        // console.log(JSON.stringify(hpo.data,null,2))
        // Object.keys(hpo.data).map( ([key,value]) => { console.log(key) } )
        // console.log(Object.entries(hpo.data).map( ([key,value]) => console.log(key + ' -> ' + value) ))
      }
      else {
        throw new Error("Could not fetch HPO data [HTTP STATUS CODE " + response.status + "]")
      }
    })
      .catch((error) => {
        console.log(error)
      })

    console.timeEnd("time")
  }


  function updateGenes(hpoTerms) {

    setLoadingGenes(true)

    fetch('/api/process', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(hpoTerms)
    })
      .then(response => response.json())
      .then(
        data => {
          // console.log(JSON.stringify(data,null,2))
          setGenes(data)
          setLoadingGenes(false)
        }
      )

    setSelectedGene(null)

  }



  function handleHpoSelect(term) {
    if (hpoTerms.includes(term) == false) {
      let updated = hpoTerms.concat(term)
      updateGenes(updated)
      updateDyData(dyParameter, updated)
      setHpoTerms(updated)
    }
  }


  function handleHpoRemove(term) {
    let updated = hpoTerms.filter(value => value !== term)
    updateGenes(updated)
    updateDyData(dyParameter, updated)
    setHpoTerms(updated)
  }


  function handleAddList(list) {

    let updated = hpoTerms.slice()
    let doUpdate = false

    list.map(term => {
      if (term in hpoData && hpoData[term].isAvailableInCada && hpoTerms.includes(term) == false) {
        updated.push(term)
        doUpdate = true
      }
    })

    if (doUpdate) {
      updateGenes(updated)
      updateDyData(dyParameter, updated)
      setHpoTerms(updated)
    }
  }



  function clearAll() {
    updateGenes([])
    updateDyData(dyParameter, [])
    setHpoTerms([])
  }


  function handleGeneSelection(geneId) {
    if (geneId !== null) {
      for (let gene of genes) {
        if (gene.geneId === geneId) {
          setSelectedGene(gene)
          return
        }
      }
    }
    setSelectedGene(null)
  }



  function validateDyParameter(dyParameter) {
    return (dyParameter.sex === 'female' || dyParameter.sex === 'male') && (dyParameter.age === 'child' || dyParameter.age === 'adult')
  }


  function updateDyData(dyParameter, hpoTerms) {
    console.log("QUERY: " + JSON.stringify(dyParameter) + " " + JSON.stringify(hpoTerms))

    if (hpoTerms.length <= 0 || validateDyParameter(dyParameter) == false) {
      setDyValue(null)
      return
    }

    fetch('/api/calcDyValue', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        params: dyParameter,
        hpoTerms: hpoTerms
      })
    })
      .then(response => response.json())
      .then(data => {
        setDyValue(data.dy ? parseFloat(data.dy) : null)
      })
  }


  function handleSexChange(sex) {
    let updated = { ...dyParameter, sex: sex }
    setdyParameter(updated)
    updateDyData(updated, hpoTerms)
  }

  function handleAgeChange(age) {
    let updated = { ...dyParameter, age: age }
    setdyParameter(updated)
    updateDyData(updated, hpoTerms)
  }

  function clearOptionalValues() {
    let updated = { sex: '', age: '' }
    setdyParameter(updated)
    updateDyData(updated, hpoTerms)
  }

  return (

    <Box display='flex' flexDirection='row' alignItems='stretch' flexGrow={1} style={{ overflow: 'hidden' }}>

      <Box display='flex' flexDirection='column' flexGrow={0} alignItems='stretch' style={{ width: '30%' }}>

        <Typography className={classes.sectionTitle} display='block' align='left' variant='overline'>
          Select Human Phenotypes
        </Typography>

        <Box className={classes.searchBox} display='flex' flexDirection='row'>
          <HpoSelect
            style={{ flex: 1 }}
            isReady={isReady}
            hpoData={hpoData}
            hpoIndex={hpoIndex}
            handleSelectEvent={handleHpoSelect}
          />

          <Box display='flex' flexDirection='row' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
            <Box style={{ margin: '8px 0px', borderLeft: '1px dashed #AAA' }} />
            <HpoListImport
              style={{ padding: '0px 4px' }}
              hpoData={hpoData}
              onApplySelection={handleAddList}
            />
            <Box style={{ margin: '8px 0px', borderLeft: '1px dashed #AAA' }} />
            <ClearAllButton style={{ padding: '0px 4px' }} clickHandler={clearAll} />
          </Box>
        </Box>

        <HpoTerms
          hpoData={hpoData}
          terms={hpoTerms}
          handleRemoveEvent={handleHpoRemove}
        />

        <Typography className={clsx(classes.sectionTitle, classes.sectionMargin)} display='block' align='left' variant='overline'>
          Optional Input
          <Tooltip title="Age and sex information is required to calculate the predicted diagnstic yield" placement="top">
            <IconButton size="small" /*onClick={props.clickHandler}*/>
              <HelpIcon style={{ color: blue[600] }} color="secondary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>



        <Box className={classes.detailView}>
          <OptionalInput
            values={dyParameter}
            handleSexChange={handleSexChange}
            handleAgeChange={handleAgeChange}
            clearAll={clearOptionalValues}
          />
        </Box>



      </Box>

      <Box style={{ margin: '0px 36px', borderLeft: '1px dashed #CCC' }} />

      <Box display="flex" flexDirection="column" flex={1} style={{}}>

        <Box display="flex" flexDirection="column" flex={1} style={{ height: '50%' }}>
          <Typography className={classes.sectionTitle} display='block' align='left' variant='overline'>
            Associated Genes
          </Typography>
          <Genes
            genes={genes}
            isLoading={loadingGenes}
            onGeneSelection={handleGeneSelection}
          />
        </Box>

        <Box display="flex" flexDirection="column" flex={1} style={{ height: '50%' }}>
          <Box className={classes.sectionMargin} display="flex" flexDirection="row">
            <Typography className={classes.sectionTitle} display='block' align='left' variant='overline'>
              Predicted Diagnostic Yield
            </Typography>
            <IconButton size="small" onClick={() => setDyHelpDialogOpen(true)}>
              <HelpIcon style={{ color: blue[600] }} color="secondary" fontSize="small" />
            </IconButton>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={dyHelpDialogOpen}
              onClose={() => setDyHelpDialogOpen(false)}
              scroll='paper'
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title" onClose={() => setDyHelpDialogOpen(false)}>Predicted diagnostic yield</DialogTitle>

              <DialogContent>
                <DialogContentText id="scroll-dialog-description" /* ref={descriptionElementRef} */ tabIndex={-1}>
                  More detailed information will be added soon
                  { /* [...new Array(50)].map(
                      () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                    )
                    .join('\n')
                  */ }
                </DialogContentText>
              </DialogContent>
              {/*
              <DialogActions>
                <Button onClick={() => setDyHelpDialogOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
              */}
            </Dialog>
          </Box>
          <DiagnosticYield
            params={dyParameter}
            plots={dyPlots}
            value={dyValue}
          />
        </Box>

      </Box>

      <Box style={{ marginLeft: '36px' }} />

      <Box display="flex" flexDirection="column" flex={1} >
        <Typography className={classes.sectionTitle} display='block' align='left' variant='overline'>
          &nbsp;
        </Typography>

        <Box className={classes.detailView}>
          <DetailView
            hpoTerms={hpoTerms}
            hpoData={hpoData}
            gene={selectedGene}
          />
        </Box>


      </Box>



    </Box>






  )

}



const DialogTitle = withStyles( (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'

  },
  icon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
  }  
})) ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <HelpIcon style={{ fontSize: 32 }} color="primary" className={classes.icon}/>
      <Typography variant="h6" style={{flex: 1}}>{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>      
    </MuiDialogTitle>
  );
});
