
import React from 'react'
// import { useState } from 'react'
import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import PropTypes, { array } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import SearchSelect from './SearchSelect.jsx'

HpoSelect.propTypes = {
    // TODO
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'flex-start'
    }
}))

export default function HpoSelect(props) {

    const classes = useStyles();

    const [items, setItems] = React.useState([])
    const [queryParts, setQueryParts] = React.useState([])
    const [fullReset, setFullReset] = React.useState(0)
    const [softReset, setSoftReset] = React.useState(0)

    useEffect(filter, [])



    function hasDigitsOnly(str) {
        if (str.length <= 0) {
            return false
        }
        for (let i = 0; i < str.length; i++) {
            if (str.charAt(i) < '0' || str.charAt(i) > '9') {
                return false
            }
        }
        return true
    }



    function filter(queryString) {

        if(typeof queryString === 'undefined') queryString = ''

        let items = []
        let parts = []

        if (queryString.length >= 3) {

            if( queryString.toLowerCase().startsWith('hp:') || hasDigitsOnly(queryString) ) {

                let query = ''

                if( queryString.toLowerCase().startsWith('hp:') ) {
                    let ending = queryString.substring(3)
                    if( ending.length >= 3 && hasDigitsOnly(ending) ) {
                        query = '*' + ending + '*'
                        parts = [ending]
                    }
                }
                else if (hasDigitsOnly(queryString)) {
                    query = '*' + queryString + '*'
                    parts = [queryString]
                }

                // console.log("SEARCH: " + query)
                items = props.hpoIndex.search(query)

            } else {

                queryString.split(' ').map( part => {
                    if( part.length >= 3 ) {
                        // parts.push('+' + part + '*')
                        parts.push(part)
                        parts.push(part + '*')
                    }
                    return null
                })

                if(parts.length > 0) {
                    let query = parts.join(' ')
                    // console.log("SEARCH: " + query)
                    items = props.hpoIndex.search(query)
                }
           }
        }

        setItems(items)
        setQueryParts(parts)
    }






    function handleKeySelect(pos) {
        if(pos >= 0 && pos < items.length) {
            let hpo = props.hpoData[items[pos].ref]
            if(hpo.isAvailableInCada) {
                props.handleSelectEvent(hpo.id)
                setSoftReset(softReset+1)
            }
        }
    }


    function renderItem(entry, hasKeyCursor) {

        // console.log("###########################################################################")

        // console.log(entry.ref)

        let values = {
            'id': entry.ref,
            'name': props.hpoData[entry.ref].name,
            'isAvailableInCada': props.hpoData[entry.ref].isAvailableInCada
        }

        let mask = {
            'id': new Array(values['id'].length).fill(false),
            'name': new Array(values['name'].length).fill(false)
        }

        for(const [token,tokenObj] of Object.entries(entry.matchData.metadata)) {
            for(const [target,targetObj] of Object.entries(tokenObj)) {
                targetObj.position.map( position => {

                    // console.log(values['id'] + " # " + values['name'] + " # " + token + " # " + target + " # " + position + " # " + JSON.stringify(queryParts))

                    if(target === 'id') {

                        if( queryParts[0] && typeof(queryParts[0] !== 'undefined') ) {
                            let start = token.indexOf(queryParts[0])
                            let length = queryParts[0].length;
                            if(start >= 0) {
                                for(let i=0; i<length; i++) {
                                    mask['id'][start+i] = true
                                }
                            }
                        }

                    } else {

                        let length = position[1]

                        queryParts.map( part => {
                            if( token.toLowerCase().startsWith(part.toLowerCase()) && part.length < length ) {
                                length = part.length
                            }
                            return null
                        })
                        
                        if( typeof mask[target] !== 'undefined' ) {
                            for(let i=0; i<length; i++) {
                                mask[target][position[0]+i] = true
                            }
                        }
                    }

                    return null
                })
            }
        }


        function hlItem(text,key) { return ( <span key={key} style={{borderBottom: '1px solid #BBB'}}>{text}</span> ) }
        function rgItem(text,key) { return ( <span key={key}>{text}</span> ) }

        function highlight(target) {

            let res = []
            let key = 0

            let last = true
            let buffer = ''

            for(let i=0; i<values[target].length; i++) {

                let current = mask[target][i]

                if(last && !current) {

                    if(buffer.length>0) res.push( hlItem(buffer,key) )
                    buffer = ''
                    key++
                    
                } else if(!last && current) {

                    if(buffer.length>0) res.push( rgItem(buffer,key) )
                    buffer = ''
                    key++
                }

                buffer += values[target].charAt(i)
                last = current
            }

            if(buffer.length>0) last ? res.push( hlItem(buffer,key) ) : res.push( rgItem(buffer,key) )

            return res
        }

        let idHL = highlight('id')
        let nameHL = highlight('name')

        return (

            <ListItem
                className={classes.listItem}

                button={values.isAvailableInCada}
                key={values['id']}

                onClick={ values.isAvailableInCada ? () => {
                    props.handleSelectEvent(values['id'])
                    setFullReset(fullReset+1) } : () => {}
                }

                style={{backgroundColor: hasKeyCursor ? "rgba(0, 0, 0, 0.04)" : null}}
            >
                <ListItemText style={{flexGrow: 0, marginRight: '6px', minWidth: 'unset'}}>
                    <Typography color="secondary" style={{color: values.isAvailableInCada ? null : "rgba(0, 0, 0, 0.26)"}}>[{idHL}]</Typography>
                </ListItemText>
                <ListItemText>
                    <Typography style={{color: values.isAvailableInCada ? null : "rgba(0, 0, 0, 0.26)"}}>
                        {nameHL}{values.isAvailableInCada ? null : <span style={{fontWeight: 'bold', color: '#fb8c00'}}>&nbsp;*</span>}
                    </Typography>
                </ListItemText>
            </ListItem>
        )
    }


    function renderSpacerItem(key) {
        return (
            <ListItem button key={key} disabled style={{visibility: 'hidden'}}>
                <ListItemText style={{flexGrow: 0, marginRight: '6px' }}>
                    <Typography color="secondary"><b>SPACER</b></Typography>
                </ListItemText>
                <ListItemText>
                    <Typography>SPACER</Typography>
                </ListItemText>
            </ListItem>
        )
    }







    return (
        <SearchSelect
            style={{...props.style}}
            filter={filter}
            items={items}
            renderItem={renderItem}
            renderSpacerItem={renderSpacerItem}
            itemsPerPage={10}
            fullReset={fullReset}
            softReset={softReset}
            bottomContent={
                <Typography display="block" variant="body2">
                    <span style={{fontWeight: 'bold', color: '#fb8c00'}}>*&nbsp;</span>not available for analysis
                </Typography>
            }
            onKeySelect={handleKeySelect}
        />
    )
}

